import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import useSWR from 'swr';
import { ActivityBanner, UserProfile, utils } from '@tidb-community/ui';
import Link from 'next/link';
// import { Footer, Header} from '@pingcap-inc/tidb-community-site-components';
import { getData } from '@tidb-community/datasource';
import { useRouter } from 'next/router';
import { ErrorPage } from '~/components';
import { Breadcrumb, Layout, Menu } from 'antd';
import * as Styled from './core.styled';
import { AuthContext, MeContext, NavContext, PageDataContext } from '~/context';
import { link as linkUtils, redDots as redDotsUtils } from '~/utils';
import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
const { Header, Content, Footer } = Layout;

// disabled for operation reasons
// const renderActivityBanner = ({ meData, isMeValidating }, { link, ...data }, onNavClick, currentPathname) => {
//   // do not render if:
//   // - already in org
//   // - meData is validating
//   // - already at the page
//   if (meData?.org || isMeValidating || currentPathname === link) {
//     return undefined;
//   }
//
//   return <ActivityBanner {...data} onClick={() => onNavClick({ link, target: '_blank' })} />;
// };

const ActivityBannerComponent = () => {
  return (
    <ActivityBanner
      // backgroundColor={'#2c2c2c'}
      style={{ background: '#084779' }}
      text={
        <>
          <svg
            style={{ fill: '#FFF' }}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8486"
            width="20"
            height="20"
          >
            <path
              d="M848 359.3H627.7L825.8 109c4.1-5.3 0.4-13-6.3-13H436c-2.8 0-5.5 1.5-6.9 4L170 547.5c-3.1 5.3 0.7 12 6.9 12h174.4l-89.4 357.6c-1.9 7.8 7.5 13.3 13.3 7.7L853.5 373c5.2-4.9 1.7-13.7-5.5-13.7zM378.2 732.5l60.3-241H281.1l189.6-327.4h224.6L487 427.4h211L378.2 732.5z"
              p-id="8487"
            ></path>
          </svg>
          中国Cloud IDE技术社区正式成立！
        </>
      }
      // backgroundImage={'https://tidb.net/images/activity/banner.svg'}
      // buttonImage={'https://tidb.net/images/activity/button.svg'}
      link={''}
      // onClick={() => onNavClick({ link: 'https://tidb.net/blog', target: '_blank' })}
    />
  );
};
const _meData = {
  id: 3666056,
  username: 'admin',
  avatar_url: 'https://asktug.com/letter_avatar_proxy/v4/letter/t/4e50e8/90.png',
  is_staff: false,
  org: null,
  org_enroll: null,
  org_invitations: [],
};
const Core = ({
  MainWrapper = Styled.Main,
  children,
  domain = 'tidb.net',
  hasMargin,
  locale = 'zh',
  backgroundColor = undefined,
}) => {
  const router = useRouter();
  const { login, logout, isLoggedIn } = useContext(AuthContext);
  const { meData: meData_ /*isMeValidating*/ } = useContext(MeContext);
  const { data: redDotsResp } = useSWR(isLoggedIn && 'operation.fetchRedDots');
  const { data: apidata } = useContext(PageDataContext);

  const redDots = redDotsUtils.transformRespToMap(redDotsResp);
  const data = getData({
    domain,
    path: router.basePath,
    locale,
    meData: _meData,
    redDots,
  }).nav;
  // const meData = _meData;
  const { navItems: headerNavItems, userProfileNavItems } = data.header;
  // const { navItems: footerNavItems, icons: footerIcons } = data.footer;

  const title = 'TiDB | COMMUNITY';
  const onNavClick = ({ link, browserLink, isSelected, target }) => {
    if (isSelected) return;
    linkUtils.handleRedirect(router, link, { as: browserLink, target });
  };

  const currentNav = utils.header.getCurrentNav(headerNavItems, router.asPath);

  const isBlogPage = router.pathname.startsWith('/blog');
  // return <ErrorPage statusCode={404} errorMsg={""}/>

  const footerData = {
    // logo,
    title,
    copyright: '©2021  Appsmith Community',
    icp: '粤ICP备2022037869号',
    icpUrl: 'https://beian.miit.gov.cn/#/Integrated/index',
  };

  const menuData = _.get(apidata, 'others.menu', []);

  return (
    <NavContext.Provider value={{ navData: data, onNavClick, currentNav }}>
      <Styled.Container style={{ backgroundColor }}>
        {/*{renderActivityBanner({ meData, isMeValidating }, data.activity, onNavClick, router.pathname)}*/}
        {!isBlogPage && <ActivityBannerComponent />}
        <Layout className="layout" theme="light">
          <Header className="layoutHeader" theme="light">
            {/* <Styled.Logo
              onClick={() => {
                router.push('/');
              }}
            /> */}
            中国Cloud IDE技术社区
            {/* <div className="logo" /> */}
            {/* <Menu mode="horizontal" defaultSelectedKeys={['2']}>
              {menuData.map((m) => {
                if (m.children) {
                  return (
                    <Menu.SubMenu key={m.value} title={<span className="submenu-title-wrapper">{m.name}</span>}>
                      {m.children.map((subM) => {
                        return (
                          <Menu.Item onClick={() => router.push(subM.link)} key={subM.value}>
                            {subM.name}
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                }
                return (
                  <Menu.Item onClick={() => router.push(m.link)} key={m.value} on>
                    {m.name}
                  </Menu.Item>
                );
              })}
            </Menu> */}
          </Header>
          <Content>
            <MainWrapper>
              {children}
              {/* <ErrorPage statusCode={404} errorMsg={""}/> */}
            </MainWrapper>
          </Content>
          {/* <Footer style={{ padding: 0 }}>
            {_.size(_.get(apidata, 'others.footer', [])) === 0 ? null : (
              <Styled.FooterS>
                {_.map(_.get(apidata, 'others.footer', []), (item, idx) => {
                  return (
                    <div key={uuidV4()}>
                      <Styled.FooterPartTitle>{item.name}</Styled.FooterPartTitle>
                      <div key={uuidV4()}>
                        {item.sections.map((it) => {
                          return (
                            <div style={{ padding: 8 }}>
                              <a alt="22" href={it.link}>
                                {it.label}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </Styled.FooterS>
            )}
            <Styled.FooterBase>
              {footerData.copyright} &nbsp;
              <a alt="22" href={footerData.icpUrl}>
                {footerData.icp}
              </a>
            </Styled.FooterBase>
          </Footer> */}
        </Layout>
      </Styled.Container>
    </NavContext.Provider>
  );
};

Core.propTypes = {
  // It belongs to an object type if MainWrapper is a styled component
  MainWrapper: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  children: PropTypes.node,
  domain: PropTypes.string,
  hasMargin: PropTypes.bool,
  locale: PropTypes.oneOf(['zh', 'en']),
};

export default Core;
