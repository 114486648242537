import React from 'react';
import NextHead from 'next/head';
import Script from 'next/script';

const Head = ({
  creator,
  description,
  faviconPathname,
  googleAnalyticsId,
  keyword,
  title,
  titleSuffix,
  isArticle = false,
  children,
}) => {
  const fullTitle = [title, titleSuffix].filter(Boolean).join(' | ');
  const keywordStr = Array.isArray(keyword) ? keyword.join(',') : keyword;
  return (
    <NextHead>
      <title>中国Cloud IDE技术社区</title>
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="description" content={description} />
      {creator && <meta name="author" content={creator} />}
      {keywordStr && <meta name="keyword" content={keywordStr} />}

      {/* 'Open Graph protocol' enables rich sharing info on Facebook */}
      {/* <meta name="og:title" content={fullTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content={isArticle ? 'article' : 'website'} />
      <meta
        name="og:image"
        content={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/android-chrome-512x512.png`}
      /> */}

      {/* for sharing to twitter */}
      {/* <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={creator} />
      <meta name="baidu-site-verification" content="code-C04vZ96Gu9" /> */}
      {/* favicons should be generated by https://realfavicongenerator.net/ */}
      {/* <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/favicon-16x16.png`}
      /> */}
      {/* <link rel="manifest" href={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/site.webmanifest`} /> */}
      {/* <link rel="shortcut icon" href={`${process.env.NEXT_PUBLIC_CDN_URL}/favicons/${faviconPathname}/favicon.ico`} /> */}
      <meta name="theme-color" content="#ffffff" />

      {/* GA setup: https://stackoverflow.com/a/62552263/14257627 */}
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`} />
      <Script
        id="gtag-init-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}');
        `,
        }}
      />

      <Script
        id="baidu-init-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var _hmt = _hmt || [];
            (function() {
             var hm = document.createElement("script");
             hm.src = "https://hm.baidu.com/hm.js?2321846dd5ff3a4f0ffeef2e2a25e218";
             var s = document.getElementsByTagName("script")[0]; 
             s.parentNode.insertBefore(hm, s);
            })();
        `,
        }}
      />

      {children}
    </NextHead>
  );
};

export default Head;
